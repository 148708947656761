<template>
    <div class="o-leave-impersonate">
        <spin-loader
            v-if="processing"
            class="c-spin-loader-overlay"
        ></spin-loader>
        <transition name="slide-width">
            <div
                v-show="!open"
                class="o-leave-impersonate__tab u-center u-primary-color__bg"
                @click="open = !open"
            >
                <i class="far fa-mask"></i>
            </div>
        </transition>
        <transition name="slide-width">
            <div
                v-show="open"
                class="o-leave-impersonate__wrap"
            >
                <div
                    class="o-leave-impersonate__container u-center u-primary-color__border"
                >
                    <div
                        class="o-leave-impersonate__hide"
                        @click="open = false"
                    >
                        <i class="fal fa-chevron-double-left u-hover--grow u-primary-color__text--hover">
                        </i>
                    </div>
                    <div class="o-leave-impersonate__text">
                        <span class="o-leave-impersonate__label">
                            {{ impersonateTypeText }}
                        </span>
                        <span class="o-leave-impersonate__title">
                            {{ userName }}
                        </span>
                    </div>
                    <div
                        class="o-leave-impersonate__leave button1
                            u-primary-color__bg--messages u-primary-color__text--hover
                            u-hover--grow u-button-cta"
                        @click="exitImpersonate"
                    >
                        {{ leaveButtonText }}
                        <i class="fal fa-sign-out-alt"></i>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'LeaveImpersonate',
    props: {
        impersonatorId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            open: true,
            processing: false,
        };
    },
    computed: {
        userName() {
            return this.nal.user.fullname;
        },
        leaveButtonText() {
            return this.nal.user.isDelegation
                ? trans('user-impersonate.leave')
                : trans('user-impersonate.stop-impersonate');
        },
        impersonateTypeText() {
            return this.nal.user.isDelegation
                ? trans('user-impersonate.impersonating') + ':'
                : trans('user-impersonate.nova-impersonating') + ':';
        },
    },
    methods: {
        async exitImpersonate() {
            this.processing = true;
            if (this.nal.user.isDelegation) {
                try {
                    await this.get('/api/delegate/leave');
                    location.reload();
                } catch (e) {
                    let message = trans('nal-lang.error.general-error');
                    if (e.status === 422) {
                        message = _(e.responseJSON.errors).values().first()[0];
                    }
                    notify(message, 'alert', true, 5000);
                    this.processing = false;
                    //return location.reload();
                }
            } else {
                window.location.href = '/stop-impersonate';
            }
        },
    },
};

</script>

<style lang="scss" scoped>

.o-leave-impersonate {
    left: 0;
    position: fixed;
    top: 100px;
    z-index: 9999;

    &__tab {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        color: #fff;
        cursor: pointer;
        flex-shrink: 0;
        font-size: 26px;
        height: 46px;
        left: 0;
        position: absolute;
        top: 0;
        width: 46px;
        
        &:hover {

            .o-leave-impersonate__tab-icon {
                transform: scale(1.1);
            }
        }
    }

    &__wrap {
        max-width: 260px;
        width: 100%;
    }

    &__container {
        background-color: #fff;
        border-bottom-right-radius: 0.5rem;
        border-right: 2px solid;
        border-top-right-radius: 0.5rem;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
        display: flex;
        flex-direction: column;
        padding: 30px;
        
    }

    &__text {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &__title {
        font-size: 15px;
        font-weight: 600;
    }

    &__label {
        color: #aaa;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__leave {
        align-items: center;
        column-gap: 10px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
    }

    &__hide {
        align-self: flex-end;
        margin-right: -20px;
        margin-top: -20px;
    }
}

.slide-width-enter {
    width: 0;
}

.slide-width-enter-active {
    overflow-x: hidden;
    transition: width 0.4s ease;
    white-space: nowrap;
}

.slide-width-leave-active {
    overflow-x: hidden;
    transition: width 0.4s ease;
    white-space: nowrap;
    width: 0;
}

</style>
